
import { mapGetters } from "vuex";
import LazyYoutubeVideo from "vue-lazy-youtube-video/dist/vue-lazy-youtube-video.min.js";

import Hero from "./../components/Hero.vue";
// import Benefits from "./../components/Benefits.vue";
// import Testimonial from "./../components/Testimonial.vue";
// import HowWorks from "./../components/HowWorks.vue";
// import Trust from "./../components/Trust.vue";

// const Hero = () => import('./../components/Hero.vue');
const Benefits = () => import("./../components/Benefits.vue");
const Testimonial = () => import("./../components/Testimonial.vue");
const HowWorks = () => import("./../components/HowWorks.vue");
const Trust = () => import("./../components/Trust.vue");

export default {
  components: {
    Hero,
    LazyYoutubeVideo,
    Benefits,
    Testimonial,
    HowWorks,
    Trust,
  },
  data() {
    return {
      toggle1: false,
      toggle2: false,
      imgHeight: "300px",
      carouselData: [
        {
          src: "/static/images/testimonial-1.jpg",
        },
        {
          src: "/static/images/testimonial-2.jpg",
        },
        {
          src: "/static/images/testimonial-3.jpg",
        },
        {
          src: "/static/images/testimonial-4.jpg",
        },
        {
          src: "/static/images/testimonial-5.jpg",
        },
      ],
    };
  },
  head() {
    return {
      title: 'American Credit | Asset Based Lending & Invoice Financing',
      meta : [
        {
          hid: 'description',
          name: 'description',
          content: 'Asset-based Business Loans & Equipment Loans up to $5M. Invoice Financing up to $2.5M. Receive your funds in as little as 3-4 Business Days. Apply Now!'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'asset-based loan, equipment loan, AR Invoice financing, Business Loans, small business financing, small business credit, american credit'
        }
      ],
      link: [
        {
          hid: 'canonical',
          rel: 'canonical',
          href: 'https://amcredit.com'
        }
      ]
    }
  },
  computed: {
  },
  methods: {
    setImgHeight() {
      const carouselItemImgList = document.getElementsByClassName(
        "index__carousel-item"
      );
      for (let i = 0; i < carouselItemImgList.length; i++) {
        this.imgHeight = `${carouselItemImgList[i].clientHeight - 3}px`;
      }
    },
    register() {
      this.$router.push({
        path: "/get-start",
      });
    },
    investerNew() {
      alert("ok");
      this.$router.push("/investerNew");
    },
  },

  mounted() {
    // document.getElementById("eventBtn").onclick = trackClick;

    var _this = this;

    function trackClick() {
      // window.Krux||((Krux=function(){Krux.q.push(arguments);}).q=[]);
      // Krux('admEvent', 'NjhcPuTz', {event_type:'clk'});
      // navigate.push("/get-start");
      _this.register();
    }
  }
};
