import { render, staticRenderFns } from "./index.vue?vue&type=template&id=44c72d82&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=44c72d82&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44c72d82",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeroCarousel: require('/Users/junmao/Develop/AmCredit/amc-nuxt/components/HeroCarousel.vue').default,HowWorks: require('/Users/junmao/Develop/AmCredit/amc-nuxt/components/HowWorks.vue').default,Benefits: require('/Users/junmao/Develop/AmCredit/amc-nuxt/components/Benefits.vue').default,Testimonial: require('/Users/junmao/Develop/AmCredit/amc-nuxt/components/Testimonial.vue').default,Trust: require('/Users/junmao/Develop/AmCredit/amc-nuxt/components/Trust.vue').default})
